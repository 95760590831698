* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background: #1c1c1c;
  color: white;
  margin: 0 10px;
}

.audio-player {
  padding: 10px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* audio info */
.audio-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.text {
  color: rgb(55, 180, 61);
}

.title {
  color: #fff;
  font-weight: 100;
  font-size: 32px;
  margin-bottom: 0;
  padding: 2px;
  line-height: 37px;
}

/* audio image */
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.audio-image {
  width: 300px;
  height: 300px;
}

.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.audio-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/* controls */
.controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  width: 100%;
  display: flex;
}

.controls button,
.volume button {
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

button svg {
  font-size: 20px;
}

.volume {
  display: flex;
  align-items: center;
}

.volume button {
  margin: 0;
}

.progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-left: 10px;
}

/* time */
.time {
  font-size: 13px;
  line-height: 46px;
}

.flex {
  display: flex;
  align-items: center;
}

button {
  color: white;
}
